const language = 'NO';

const url = new URL(window.location);
const params = new URLSearchParams(url.search.slice(1));


const type = params.get('type');

const getTimeStrings = (language) => {
  //TODO handle more languages
  return {
    hours: ['tolv', 'ett','to', 'tre', 'fire', 'fem', 'seks', 'sju', 'åtte', 'ni', 'ti', 'elleve', 'tolv', 'ett','to', 'tre', 'fire', 'fem', 'seks', 'sju', 'åtte', 'ni', 'ti', 'elleve'],
    minutes: ['fem', 'ti'],
    quarter: 'kvart',
    half: 'halv',
    to: 'på',
    past: 'over',
  }
};

const enabledColor = 'rgb(0,0,0)';

const disabledColor = 'rgba(200,200,200,0.1)';

const timeStrings = getTimeStrings(language);

const theTimeis = 'Klokka er ';

const getNaturalTime = (hours, minutes, timeStrings) =>
{
  let timestring;
  switch (true) {
    case minutes >= 5 && minutes < 10:
      timestring = timeStrings.minutes[0] + ' ' + timeStrings.past + ' ' + timeStrings.hours[hours];
      break;
    case (minutes >= 10 && minutes < 15):
      timestring = timeStrings.minutes[1] + ' ' + timeStrings.past + ' ' + timeStrings.hours[hours];
      break;
    case minutes >= 15 && minutes < 20:
      timestring = timeStrings.quarter + ' ' + timeStrings.past + ' ' + timeStrings.hours[hours];
      break;
    case minutes >= 20 && minutes < 25:
      timestring = timeStrings.minutes[1] + ' ' + timeStrings.to + ' ' + timeStrings.half + ' ' + timeStrings.hours[hours + 1];
      break;
    case minutes >= 30 && minutes < 35:
      timestring = timeStrings.half + ' ' + timeStrings.hours[hours + 1];
      break;
    case minutes >= 25 && minutes < 30:
      timestring = timeStrings.minutes[0] + ' ' + timeStrings.to + ' ' + timeStrings.half + ' ' + timeStrings.hours[hours + 1];
      break;
    case minutes >= 50 && minutes < 55:
      timestring = timeStrings.minutes[1] + ' ' + timeStrings.to + ' ' + timeStrings.hours[hours + 1];
      break;
    case minutes >= 55 && minutes < 60:
      timestring = timeStrings.minutes[0] + ' ' + timeStrings.to + ' ' + timeStrings.hours[hours + 1];
      break;
    case minutes >= 45 && minutes < 50:
      timestring = timeStrings.quarter + ' ' + timeStrings.to + ' ' + timeStrings.hours[hours + 1];
      break;
    case minutes >= 40 && minutes < 45:
      timestring = timeStrings.minutes[1] + ' ' + timeStrings.past + ' ' + timeStrings.half + ' ' + timeStrings.hours[hours + 1];
      break;
    case minutes >= 35 && minutes < 40:
      timestring = timeStrings.minutes[0] + ' ' + timeStrings.past + ' ' + timeStrings.half + ' ' + timeStrings.hours[hours + 1];
      break;
    case minutes >= 0 && minutes < 5:
      timestring = timeStrings.hours[hours];
      break;
  }
  return theTimeis + timestring;
}

const getMinuteIndex = (minutes) =>
{
  let index = -1;
  switch (true) {
    case minutes >= 5 && minutes < 10:
      index = 0;
      break;
    case (minutes >= 10 && minutes < 15):
      index = 1;
      break;
    case minutes >= 20 && minutes < 25:
      index = 1;
      break;
    case minutes >= 25 && minutes < 30:
      index = 0;
      break;
    case minutes >= 50 && minutes < 55:
      index = 1;
      break;
    case minutes >= 55 && minutes < 60:
      index = 0;
      break;
    case minutes >= 40 && minutes < 45:
      index = 1;
      break;
    case minutes >= 35 && minutes < 40:
      index = 0;
      break;
  }
  return index;
}

const getHourIndex = (hours, minutes) => {
  let index = -1;
  switch (true) {
    case minutes >= 5 && minutes < 10:
      index = hours;
      break;
    case (minutes >= 10 && minutes < 15):
      index = hours;
      break;
    case minutes >= 15 && minutes < 20:
      index = hours;
      break;
    case minutes >= 0 && minutes < 5:
      index = hours;
      break;
    case minutes >= 20 && minutes < 25:
      index = hours + 1;
      break;
    case minutes >= 30 && minutes < 35:
      index = hours + 1;
      break;
    case minutes >= 25 && minutes < 30:
      index = hours + 1;
      break;
    case minutes >= 50 && minutes < 55:
      index = hours + 1;
      break;
    case minutes >= 55 && minutes < 60:
      index = hours + 1;
      break;
    case minutes >= 45 && minutes < 50:
      index = hours + 1;
      break;
    case minutes >= 40 && minutes < 45:
      index = hours + 1;
      break;
    case minutes >= 35 && minutes < 40:
      index = hours + 1;
      break;
  }
  return index;
};

const renderNaturalTime = () => {
  const currentDate = new Date();
  const minutes = currentDate.getMinutes();
  const hours = currentDate.getHours();
  const templateContainer = document.querySelector('#template_1');
  while (templateContainer.firstChild) {
    templateContainer.removeChild(templateContainer.firstChild);
  }
  const template1 = document.querySelector('#timeis');
  const clone = template1.content.cloneNode(true);
  const p = clone.querySelector('p');
  const naturalTime = getNaturalTime(hours, minutes, timeStrings);
  p.textContent = naturalTime;
  templateContainer.appendChild(clone);
  return clone;
};

const renderDots = () => {
  const currentDate = new Date();
  const seconds = currentDate.getSeconds();
  const dotsTC = document.querySelector('#dotsContainerP');
  while (dotsTC.firstChild) {
    dotsTC.removeChild(dotsTC.firstChild);
  }
  const dotsTemplate = document.querySelector('#dots');
  const dotsArray1 = Array(seconds);
  const dotsArray2 = Array(60 - seconds);
  dotsArray1.fill('⚫');
  dotsArray2.fill('⚪');
  const dotsArray = [...dotsArray1, ...dotsArray2];
  const dotsContainerClone = dotsTemplate.content.cloneNode(true);
  const p2 = dotsContainerClone.querySelector('p');
  p2.textContent = dotsArray.toString().replaceAll(',', '');
  dotsTC.appendChild(dotsContainerClone);
  return dotsContainerClone;
};

const mountTemplate = (language) => {
  const template = document.querySelector('#clock_template');
  const container = document.querySelector('#clock_template_container');
  const templateContentClone = template.content.cloneNode(true);

  while (container.firstChild) {
    container.removeChild(container.firstChild);
  }

  container.appendChild(templateContentClone);
};

const renderClock = () => {

  const currentDate = new Date();
  const minutes = currentDate.getMinutes();
  const hours = currentDate.getHours();
  const container = document.querySelector('#clock_template_container');
  const allWords = container.querySelectorAll('#clock_template_container span:not(.static)');
  allWords.forEach((word) => {
    word.style.color = disabledColor;
  });
  const naturalTimeArray = getNaturalTime(hours, minutes, timeStrings).split(' ');
  const naturalTimeArrayNext = getNaturalTime(hours, minutes + 5, timeStrings).split(' ');

  const hourIndex = getHourIndex(hours, minutes);
  const hourIndexNext = getHourIndex(hours, minutes >= 55 ? minutes - 55 : minutes);

  const minuteIndex = getMinuteIndex(minutes);
  const minuteIndexNext = getMinuteIndex(minutes + 5);

  const prepositionToIndex = naturalTimeArray.findIndex(p => p === 'på');
  const prepositionToIndexNext = naturalTimeArrayNext.findIndex(p => p === 'på');

  const prepositionOverIndex = naturalTimeArray.findIndex(p => p === 'over');
  const prepositionOverIndexNext = naturalTimeArrayNext.findIndex(p => p === 'over');

  const quarterIndex = naturalTimeArray.findIndex(q => q === 'kvart');
  const quarterIndexNext = naturalTimeArrayNext.findIndex(q => q === 'kvart');

  const halfIndex = naturalTimeArray.findIndex(h => h === 'halv');
  const halfIndexNext = naturalTimeArrayNext.findIndex(h => h === 'halv');

  const hourElement = container.querySelector(`#hours_${hourIndex}`) || container.querySelector(`#hours_${hourIndex - 12}`);
  if (hourElement) {
    hourElement.style.color = enabledColor;
  }
  const transitionColor = `rgba(150,150,150,${minutes % 5 / 5})`;
  console.log(transitionColor);
  console.log(minutes % 5 / 5);
  const hourElementNext = container.querySelector(`#hours_${hourIndexNext}`) || container.querySelector(`#hours_${hourIndexNext - 12}`);
  if (hourElementNext && hourIndex !== hourIndexNext && type === 'shade') {
    hourElementNext.style.color = transitionColor;
  }

  const minuteElement = container.querySelector(`#minutes_${minuteIndex}`);
  if (minuteElement) {
    minuteElement.style.color = enabledColor;
  }

  const minuteElementNext = container.querySelector(`#minutes_${minuteIndexNext}`);
  if (minuteElementNext && type === 'shade') {
    minuteElementNext.style.color = transitionColor;
  }

  console.log(prepositionOverIndex, prepositionToIndex);
  if (prepositionToIndex > -1 && prepositionToIndex < halfIndex) {
    container.querySelector('#to_0').style.color = enabledColor;
  } else if (prepositionToIndex > -1 && prepositionToIndex > halfIndex) {
    container.querySelector('#to_1').style.color = enabledColor;
  }
  if (prepositionOverIndex > -1 && prepositionOverIndex < halfIndex) {
    container.querySelector('#over_0').style.color = enabledColor;
  } else if (prepositionOverIndex > -1 && prepositionOverIndex > halfIndex) {
    container.querySelector('#over_1').style.color = enabledColor;
  }

  if (quarterIndex > -1) {
    container.querySelector('#quarter').style.color = enabledColor;
  }
  if (halfIndex > -1) {
    container.querySelector('#half').style.color = enabledColor;
  }

  if (prepositionToIndexNext > -1 && prepositionToIndexNext < halfIndexNext && prepositionToIndex === -1 && type === 'shade') {
    container.querySelector('#to_0').style.color = transitionColor;
  } else if (prepositionToIndexNext > -1 && prepositionToIndexNext > halfIndexNext && prepositionToIndex === -1 && type === 'shade') {
    container.querySelector('#to_1').style.color = transitionColor;
  }

  if (prepositionOverIndexNext > -1 && prepositionOverIndexNext < halfIndexNext && prepositionOverIndex === -1 && type === 'shade') {
    container.querySelector('#over_0').style.color = transitionColor;
  } else if (prepositionOverIndexNext > -1 && prepositionOverIndexNext > halfIndexNext && prepositionOverIndex === -1 && type === 'shade') {
    container.querySelector('#over_1').style.color = transitionColor;
  }

  if (quarterIndexNext > -1 && quarterIndex === -1 && type === 'shade') {
    container.querySelector('#quarter').style.color = transitionColor;
  }

  if (halfIndexNext > -1 && halfIndex === -1 && type === 'shade') {
    container.querySelector('#half').style.color = transitionColor;
  }

}

// #clock_template_container span:not(.static)
const render = () => {
  renderClock();
  // renderNaturalTime();
  renderDots();
};

mountTemplate('NO');

const intervalRef = setInterval(render, 1000);
